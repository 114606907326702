import './src/styles/app.scss'
import { type GatsbyBrowser } from 'gatsby'
import * as React from 'react'

const App: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <>{children}</>
}

export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = ({
  element
}) => {
  return <App>{element}</App>
}

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = () => {
  return [0, 0]
}
